<template>
  <div class="usdtCourse">
    <div v-title
         data-title="了解USDT"></div>
    <div class="Navbar">
      <img @click="gotoback"
           :src="imgUrl + 'left-arrow2.png'" />
      <div class="title">了解USDT</div>
    </div>

    <div class="usdt-box"
         style="margin-top: 53px">
      <div class="banner">
        <img src="../assets/img/usdt/banner.png" />
      </div>
      <div class="usdt-title"><img src="../assets/img/usdt/title1.png" /></div>
      <div class="usdt-item">
        <div class="item">
          <span></span>其性质与实体货币相似，允许在互联网上即时、无地虚拟币是一种不具备实体形式的、仅以数字形式存在的货币，也称之为“数字货币”。
        </div>
        <div class="item">
          <span></span>其性质与实体货币相似，允许在互联网上即时、无地理限制转让，主流的数字货币如USDT(泰达币)、BTC
          (比特币)、ETH(以太坊)等。
        </div>
        <div class="item">
          <span></span>用户可以通过数字货币交易平台购买数字货币，在平台进行支付、兑换成人民币从而进行游戏。
        </div>
      </div>
    </div>

    <div class="usdt-box"
         style="margin-top: 10px">
      <div class="usdt-title"
           style="margin-bottom: 20px">
        <img src="../assets/img/usdt/title2.png" />
      </div>
      <div class="usdt-item">
        <div class="item2">
          <div class="item2-img">
            <img src="../assets/img/usdt/tiem2-1.png" />
          </div>
          <div>
            <div class="text-title"><span></span>高度加密</div>
            与银行卡等传统交易方式相比,用户不需要给出自己的姓名或卡号即可完成虚拟币交易,避免敏感信息泄露.
          </div>
        </div>
        <div class="item2">
          <div>
            <div class="text-title"><span></span>快速交易</div>
            虚拟币所采用的区块链技术具有去中心化特点，不需要清算中心机构来处理数据，交易时间大大缩短.
          </div>
          <div class="item2-img">
            <img src="../assets/img/usdt/tiem2-2.png" />
          </div>
        </div>
        <div class="item2">
          <div class="item2-img">
            <img src="../assets/img/usdt/tiem2-1.png" />
          </div>
          <div>
            <div class="text-title"><span></span>去中心化</div>
            不由央行或当局发行，不受银行监管,用户可随心所欲的使用存放在自己数字钱包里的资金
          </div>
        </div>
      </div>
    </div>

    <div class="usdt-box"
         style="margin-top: 10px">
      <div class="usdt-title"
           style="margin: 10px 40px;margin-bottom: 20px">
        <img src="../assets/img/usdt/title3.png" />
      </div>
      <div class="usdt-item2">
        <div class="item2-img"><img src="../assets/img/usdt/item3-1.png" /></div>
        <div class="item2-text">
          泰达币(Tether）也被称为USDT，其 价值等同于美元。<br /> 1USDT=1美元,该特点使USDT成为 全球加密数字货币中规模最大、流通性最高的稳定币，具有良好的保值性， 也是网站所使用的主要币种。
        </div>
      </div>
      <div class="usdt-item">
        <div class="item2">
          <div>
            <div class="text-title"
                 style="line-height: 40px;"><img src="../assets/img/usdt/item3-2.png" />高度加密</div>
            USDT在涨跌幅上不会出现巨大的波动，只跟随美元汇率的变化而变化，价格非常稳定.
          </div>
        </div>
        <div class="item2">
          <div>
            <div class="text-title"
                 style="line-height: 40px;"><img src="../assets/img/usdt/item3-3.png" />快速交易</div>
            大部份币种不能直观看到相应的价值。需要计算转换，非常不便。而USDT可以直观的看到价值多少美元
          </div>
        </div>
        <div class="item2">
          <div>
            <div class="text-title"
                 style="line-height: 40px;"><img src="../assets/img/usdt/item3-4.png" />去中心化</div>
            USDT的流通是基于比特币区块链，稳定可靠。由于其恒定价值的特性，可随时转换为其他币种流通
          </div>
        </div>
      </div>
    </div>

    <div class="usdt-box"
         style="margin-top: 10px;margin-bottom:20px">
      <div class="usdt-title"
           style="margin: 10px 15px;margin-bottom: 20px">
        <img src="../assets/img/usdt/title4.png" />
      </div>
      <div class="usdt-item4">
        <img src="../assets/img/usdt/item4-4.png"
             @click="gotolink(6)">
        <img src="../assets/img/usdt/item4-5.png"
             @click="gotolink(1)">
        <img src="../assets/img/usdt/item4-6.jpg"
             @click="gotolink(2)">

      </div>
      <p style="font-family: PingFang SC;font-weight: 500;color: #EF5353;font-size:14px;margin:10px 20px;text-align: center;">
        *您也可以选中其他数字货币交易平台进行数字货币的买卖！</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() { },
  methods: {
    gotoback() {
      uni.postMessage({
        data: {
          tabbarIndex: 1,
        },
      });
      uni.navigateBack({});
    },
    gotolink(index) {
      if (Object.keys(res)[0] === 'h5') {
        if (index == 6) {
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'openUrl',
                openUrl: 'https://www.okex.com/cn/download',
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else if (index == 2) {
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'openUrl',
                openUrl: 'https://www.gate.io/cn/',
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else if (index == 1) {
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'openUrl',
                openUrl: 'https://www.zb.com/',
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        }
      } else {
        if (index === 1) {
          /*  window.open('https://www.zb.com/', '_blank') */
          uni.postMessage({
            data: {
              openUrl: 'https://www.zb.com/'
            }
          });
        } else if (index === 2) {
          /* window.open('https://www.gate.io/cn/', '_blank') */
          uni.postMessage({
            data: {
              openUrl: 'https://www.gate.io/cn/'
            }
          });
        } else if (index === 6) {
          /* window.open(' https://www.mexc.com/zh-CN', '_blank') */
          uni.postMessage({
            data: {
              openUrl: 'https://www.okex.com/cn/download'
            }
          });
        }
      }



    }
  },
};
</script>

<style lang="less">
body {
  background-color: #f5f5f5;
}

ul,
li {
  list-style: none;
}

.usdtCourse {
  .Navbar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 43px;
    width: 100%;
    background-color: #fff;
    padding: 0 20px;

    > img {
      height: 15px;
      width: 15px;
    }

    .title {
      flex: 1;
      text-align: center;
      color: #000;
    }
  }

  .banner {
    > img {
      width: 100%;
    }
  }

  .usdt-box {
    background-color: var(--w-bgcolor);
    padding: 10px;
    margin: 0 10px;
    border-radius: 8px;

    .usdt-title {
      margin: 10px 50px;

      img {
        width: 100%;
      }
    }

    .usdt-item {
      .item {
        display: flex;
        font-family: PingFang SC;
        font-weight: 400;
        color: var(--li-colortext);
        font-size: 13px;
        margin-bottom: 10px;
        line-height: 18px;
      }

      .item2 {
        display: flex;
        font-family: PingFang SC;
        font-weight: 400;
        color: var(--li-colortext);
        font-size: 13px;
        margin-bottom: 20px;
        line-height: 18px;

        .text-title {
          display: flex;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #cdaf8d;
          margin-bottom: 5px;

          > img {
            margin-right: 10px;
            width: 40px;
            height: 40px;
          }
        }

        .item2-img {
          min-width: 120px;
          margin: 0 10px;
          margin-right: 15px;

          &:nth-child(2) {
            margin-right: 10px;
            margin-left: 15px;
          }

          img {
            width: 100%;
          }
        }
      }

      span {
        display: block;
        min-width: 10px;
        height: 10px;
        background: #cdaf8d;
        border-radius: 50%;
        margin-right: 10px;
        margin-top: 5px;
      }
    }

    .usdt-item2 {
      display: flex;
      align-items: center;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000;
      font-size: 13px;
      margin-bottom: 10px;
      line-height: 18px;

      .item2-img {
        min-width: 100px;
        height: 100%;

        > img {
          width: 100%;
        }
      }

      .item2-text {
        margin-left: 10px;
      }
    }

    .usdt-item4 {
      img {
        width: 100%;
      }
    }
  }
}
</style>
